.offer-summary__card-image {
  aspect-ratio: 3/2;
  object-fit: cover;
}

.offer-summary-offer-img {
  img {
    aspect-ratio: 5/3;
    object-fit: cover;
  }
}
