.prim-colour-bg{
    background-color: $prim-colour!important;
}
.sec-colour-bg{
    background-color: $sec-colour!important;
}
.thrd-colour-bg{
    background-color: $thrd-colour!important;
}
.prim-colour{
    color: $prim-colour;
}
.sec-colour{
    color: $sec-colour!important;
}
.thrd-colour{
    color: $thrd-colour;
}
.inherit{
    background-color: rgba(255,255,255,0.2)
}
.inherit2{
    background-color: rgba(0,0,0,0.6)
}
.inherit3{
    background-color: rgba(255,255,255,0.8)
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border-color: rgb(227, 186, 179);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(227, 186, 179, 0.6);
  outline: 0 none;
}

@media(max-width:500px){
    .border-fix{
        border: hidden!important;
    }
}

html{
    scroll-behavior: smooth;
}

html, body {
    overflow-x: hidden;
}

.rdtCount, .rdtDays, .rdtOld, .rdtMonth, .rdtYear{
    color: lightgray !important;
}

.rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle{
    color: #e3bab3 !important;
}

.input-group-append{
    // width: 8%;
}

.rdt {
    width: 92%;

    input{
        text-align: center;
    }
}

#weddingDatetime{
    // width: 90%;
}

#WeddingDatePicker{
    flex-wrap: nowrap;
}

.container .carousel .slide img {
    // max-height: 100vh;
    // width: auto; /*100vw*/
  }

  .react-images__track{
    align-items: start!important;
  }